<template>

  <div class="d-flex align-center">
    <span class="mr-2">Hamochi</span>
    <v-chip
      small
      class="mr-1"
    >Beta {{$version}}</v-chip>
    <v-chip
      v-if="env != 'production' && env != 'release'"
      small
      class="d-none d-sm-flex"
    >{{env}}</v-chip>
  </div>
</template>

<script>
  export default {
    data: () => ({
      env:process.env.VUE_APP_ENV,
    }),
  }
</script>
